import logo from './logo.svg';
import './App.css';
import bg from './assets/right.png'
import { useEffect, useState } from 'react';
import useViewportHeight from './useViewPortHeight';
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const vw = useViewportHeight();
  const vh = useViewportHeight();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 900);
    if (window.innerWidth >= 900) {
      setIsOpen(false); // Close menu when resizing to desktop
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      id="_690_6034_Room_23"
      style={{
        height: "100%",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden"
      }}
    >
      <div style={{ background: "rgba(189, 189, 189, 1.00)", width: '100%' }}>
        {/* Navbar Header */}
        <div
          style={{
            display: isMobile ? "flex" : "none",
            justifyContent: "space-between",
            padding: "10px",
            alignItems: "center",
  
          }}
        >

          {/* Hamburger Icon - visible only on mobile */}
          {isMobile && (
            <div
              onClick={toggleMenu}
              style={{
                display: "flex",
                justifyContent: "space-between",  // Align the content to opposite ends (left for icon, center for text)
                alignItems: "center", 
                justifyItems:'center', // Vertically center the items
                width: "100%",  // Ensure it takes up full width
                  cursor: "pointer",
                  height:"50%"
              }}
            >
              {/* Hamburger Icon - aligned to the left */}
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ height: "3px", width: "25px", backgroundColor: "#000", margin: "4px 0" }} />
                <div style={{ height: "3px", width: "25px", backgroundColor: "#000", margin: "4px 0" }} />
                <div style={{ height: "3px", width: "25px", backgroundColor: "#000", margin: "4px 0" }} />
              </div>

              {/* Text next to Hamburger Icon - centered */}
              <span
                style={{
                  color: "#000",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",  // Keep the text centered
                  position: "absolute", 
                  left: "50%",
                  transform: "translateX(-50%)",  // Offset by 50% to fully center
                }}
              >
                Ek_Tha_Trader
              </span>
            </div>

          )}

        </div>

        {/* Mobile Menu - visible only when open on mobile */}
        {isMobile && isOpen && (
          <div
            style={{
              textAlign: "center",
              paddingBottom: "20px",
              display: "block",
            }}
          >
            {[
              { name: "Home", url: "/" },
              { name: "Instagram", url: "https://www.instagram.com/ek_tha_trader_?igsh=MTU1cDBmdGJ1NWpyNg==" },
              { name: "Whalesbook", url: "https://www.whalesbook.com" },
              { name: "YouTube", url: "https://youtube.com/@ekthatrader-by-maznah?si=aExOPdo8om_ix0JX" },
              { name: "LinkedIn", url: "https://www.linkedin.com/in/maznah-khan-534b20286?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" },
              { name: "Contact Us", url: "https://www.instagram.com/ek_tha_trader_?igsh=MTU1cDBmdGJ1NWpyNg==" },
            ].map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                id={item.name.replace(/\s+/g, '_')}
                style={{
                  display: "block",
                  color: "#000000ff",
                  fontFamily: 'Roboto',
                  fontSize: "20px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  padding: "10px 0",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {item.name}
              </a>
            ))}
          </div>

        )}

        {/* Desktop Menu - visible only on desktop */}
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "20px 0px",
            }}
          >
            {[
              { name: "Home", url: "/" },
              { name: "Instagram", url: "https://www.instagram.com/ek_tha_trader_?igsh=MTU1cDBmdGJ1NWpyNg==" },
              { name: "Whalesbook", url: "https://www.whalesbook.com" },
              { name: "YouTube", url: "https://youtube.com/@ekthatrader-by-maznah?si=aExOPdo8om_ix0JX" },
              { name: "LinkedIn", url: "https://www.linkedin.com/in/maznah-khan-534b20286?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" },
              { name: "Contact Us", url: "https://www.instagram.com/ek_tha_trader_?igsh=MTU1cDBmdGJ1NWpyNg==" },
            ].map((item, index) => (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#000000ff",
                  fontFamily: 'Roboto',
                  fontSize: "20px",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {item.name}
              </a>
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          background: "#825eb6",
          display: "flex",
          flex: 1,
          flexWrap: "wrap-reverse"
        }}
      >
        <div
          id='left'
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "40px",
            justifyContent: "center",
            flex: 1,
            minWidth: "600px",
            gap: 25

          }}>
          <div
            id="Ek_Tha_Trader_Simplifying_Finance___Empowering_Traders_"
            style={{
              color: "#ffffffff",
              fontFamily: 'Roboto',
              fontSize: "46.294918060302734px",
              fontWeight: "600",
              lineHeight: "59px",
              textAlign: "left",
              width: "75%"
            }}
          >
            Ek_Tha_Trader Simplifying Finance &amp; Empowering Traders.
          </div>

          <div
            id="Join_Ek_Tha_Trader_on_a_journey_of_financial_wisdom__trading_strategies__and_market_insights__Simplifying_finance_for_aspiring_traders_and_empowering_you_to_take_charge_of_your_financial_future__Let_s_trade_smarter__together_"
            style={{
              color: "#ffffffff",
              fontFamily: 'Roboto',
              fontSize: "26.454240798950195px",
              fontWeight: "400",
              lineHeight: "37px",
              textAlign: "left",
              width: "98%"
            }}
          >
            Join Ek_Tha_Trader on a journey of financial wisdom, trading strategies,
            and market insights. Simplifying finance for aspiring traders and
            empowering you to take charge of your financial future. Let&#39;s trade
            smarter, together!
          </div>

          <div
            style={{
              padding: "20px 20px",
              background: "rgba(255, 255, 255, 0.36)",
              borderRadius: "8.762736320495605px",
              backdropFilter: "blur(30.66957664489746px)",
            }}>
            <span
              id="Catch_me_live_on_Whalesbook_daily_Monday_to_Friday__9_PM_to_10_30_PM_"
              style={{
                color: "#000000ff",
                fontFamily: 'Roboto',
                fontSize: "34.28702163696289px",
                fontWeight: "600",
                lineHeight: "44px",
                textAlign: "left",
                width: "75%"
              }}
            >
              Catch me live on Whalesbook daily Monday to Friday, 9 PM to 10:30 PM!
            </span>
          </div>
        </div>
        <div
          id='right'
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            minWidth: 700,
            padding: "20px",
            background:
              "linear-gradient(90.00000000000024deg, rgba(129, 93, 182, 1.00) 0.0%, rgba(96, 69, 136, 1.00) 0.009999999747378752%, rgba(93, 67, 131, 1.00) 50.0%, rgba(57, 41, 80, 1.00) 100.0%)",
          }}>
          {isMobile ?
            <img
              id="_690_6052_Add_a_subheading__3__1"
              src={bg}
              alt="Add a subheading"
              style={{
                borderRadius: "30.98px",
                width: "90%",
                height: "100%"
              }}
            /> :
            <img
              id="_690_6052_Add_a_subheading__3__1"
              src={bg}
              alt="Add a subheading"
              style={{
                borderRadius: "30.98px",
                width: vw / 1.2,
                height: vh - 103.5
              }}
            />
          }
        </div>
      </div>



    </div>
  );
}

export default App;
